.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
}

.photo-item {
  flex-basis: calc(33.333% - 16px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.photo-item img {
  width: 100%;
  height: auto;
  display: block;
}

.card img {
  cursor: pointer;
  transition: transform 0.2s;
}

.card img:hover {
  transform: scale(1.05);
}
