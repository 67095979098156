.pdf-viewer {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.document-container {
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 750px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.document-container .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.document-container .react-pdf__Page {
  max-width: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.document-container .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.controls {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.card {
  transition: transform 0.2s, box-shadow 0.2s;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 0 auto;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-img-top {
  height: 250px !important;
  object-fit: contain !important;
  padding: 1rem;
  background-color: #f8f9fa;
}

.card-body {
  padding: 1.5rem;
}

.btn-primary {
  background-color: #1B1A55;
  border-color: #1B1A55;
  padding: 0.5rem 1.5rem;
  font-weight: 500;
  transition: all 0.2s;
}

.btn-primary:hover:not(:disabled) {
  background-color: #15144d;
  border-color: #15144d;
  transform: translateY(-2px);
}

.btn-primary:disabled {
  background-color: #1B1A55;
  border-color: #1B1A55;
  opacity: 0.7;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  padding: 0.5rem 1.5rem;
  font-weight: 500;
  transition: all 0.2s;
}

.btn-secondary:hover:not(:disabled) {
  background-color: #5a6268;
  border-color: #545b62;
  transform: translateY(-2px);
}

.btn-secondary:disabled {
  opacity: 0.7;
}

/* Loading spinner styles */
.spinner-border {
  width: 3rem;
  height: 3rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .card {
    margin-bottom: 1rem;
  }
  
  .pdf-viewer {
    padding: 10px;
  }
  
  .document-container {
    min-height: 500px;
  }
  
  .controls {
    flex-direction: column;
    width: 100%;
  }
  
  .controls button {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .document-container .react-pdf__Page canvas {
    width: 100% !important;
    height: auto !important;
  }
}
